import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';

import { SettingsService } from '../settings/settings.service';
import { AuthTokenService } from '../../../auth/services/auth-token/auth-token.service';
import { AdditionalSettingsGroupModel } from '../../models/additional-settings-group/additional-settings-group.model';
import { AdditionalSettingsEnum } from '../../enums/additional-settings/additional-settings.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentAdditionalSettingsService {
  private currentAdditionalSettings: BehaviorSubject<AdditionalSettingsGroupModel | null>;

  constructor(private settingsService: SettingsService, private authTokenService: AuthTokenService) {
    this.currentAdditionalSettings = new BehaviorSubject<AdditionalSettingsGroupModel | null>(null);
  }

  public initAdditionalSettings(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return this.updateCurrentAdditionalSettings();
  }

  public getCurrentAdditionalSettings(): AdditionalSettingsGroupModel | null {
    return this.currentAdditionalSettings.value;
  }

  public getSimpleRepairOrderValue(): string | null {
    return this.currentAdditionalSettings.value?.simpleRepairOrder.value || null;
  }

  public updateCurrentAdditionalSetting(key: AdditionalSettingsEnum, value: string | number): Observable<void> {
    const groupId: string = this.currentAdditionalSettings.value?.id || '';

    return this.settingsService.updateAdditionalSettings(key, value, groupId).pipe(switchMap(() => this.updateCurrentAdditionalSettings()));
  }

  public updateCurrentAdditionalSettings(): Observable<void> {
    return this.settingsService.getAdditionalSettings().pipe(
      tap((currentAdditionalSettings: AdditionalSettingsGroupModel) => {
        this.setCurrentAdditionalSettings(currentAdditionalSettings);
      }),
      map(() => void 0)
    );
  }

  public clearCurrentAdditionalSettings(): void {
    this.currentAdditionalSettings.next(null);
  }

  private setCurrentAdditionalSettings(currentAdditionalSettings: AdditionalSettingsGroupModel): void {
    this.currentAdditionalSettings.next(currentAdditionalSettings);
  }
}
